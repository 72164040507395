<template>
  <div>
    <el-form ref="form" v-cus-loading="form.loading" :model="form.model" :rules="form.rules" label-position="top">
      <el-card shadow="never">
        <el-row>
          <el-col :span="12">
            <span>{{ $tc('voucher', 1) }}</span>
          </el-col>
          <el-col :span="12">
            <el-button v-cus-loading="form.loading" size="mini" style="float: right" type="primary" @click="onUpdateChild">
              {{ $tc('save') }}
            </el-button>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header">
          <span>{{ $tc('period', 1) }}</span>
        </div>
        <br>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :label="$tc('vendor')" prop="vendorUserId">
              <select-user-is-seller v-model="form.model.vendorUserId" :label-all="false"></select-user-is-seller>
            </el-form-item>
            <el-form-item :label="$tc('service')" prop="serviceId">
              <select-service v-model="form.model.serviceId" :label-all="false" @set-selected-entity="onSetService"></select-service>
            </el-form-item>
            <el-form-item :label="$tc('company')" prop="companyKey">
              <select-company-by-service v-if="roleIsAdministrator" v-model="form.model.companyKey" :label-all="false" :service-id="form.model.serviceId" @set-selected="onChangeCompany"></select-company-by-service>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$tc('date')" prop="voucherDate">
              <el-date-picker v-model="form.model.voucherDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" @change="onChangePeriod(form.model.voucherRecurrence)"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$tc('due')" prop="voucherDateDue">
              <el-date-picker v-model="form.model.voucherDateDue" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"></el-date-picker>
              <span class="help">
                <el-tooltip :content="$tc('helpVoucherDateDue')">
                  <i class="fad fa-info-circle"> </i>
                </el-tooltip>
              </span>
            </el-form-item>
          </el-col>
          <el-col v-if="roleIsAdministrator" :span="6">
            <el-form-item :label="$tc('start')" prop="voucherDateStart">
              <el-date-picker v-model="form.model.voucherDateStart" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="roleIsAdministrator" :span="6">
            <el-form-item :label="$tc('end')" prop="voucherDateEnd">
              <el-date-picker v-model="form.model.voucherDateEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$tc('recurrence')" prop="voucherRecurrence">
              <select-service-recurrence v-model="form.model.voucherRecurrence" :label-all="false" @change="onChangeRecurrence($event)"></select-service-recurrence>
              <div style="text-align: right">
                <small>
                  <em>
                    {{ $tc('period') }}: &nbsp;
                    {{ form.model.voucherDateStart | formatToDate }}
                  </em>
                  -
                  <em>{{ form.model.voucherDateEnd | formatToDate }}</em>
                </small>
              </div>
            </el-form-item>
            <el-form-item :label="$tc('description')" prop="voucherDescription">
              <el-input v-model="form.model.voucherDescription" :placeholder="$tc('description')" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card class="box-card" shadow="false">
        <div slot="header" class="clearfix">
          <span>{{ $tc('company') }}</span>
          <el-button size="mini" style="float: right" plain type="primary" @click="onClickAddDetail">
            <i class="fad fa-plus"></i>
            &nbsp; {{ $tc('add') }}
          </el-button>
        </div>
        <div v-for="(item, index) in form.model.voucherDetails" :key="index">
          <voucher-detail :key="index" ref="voucherDetail" :index="index" :model="item" :rules="form.rules.companyKey" :service-id="form.model.serviceId" :voucher-date="form.model.voucherDate" :voucher-recurrence="form.model.voucherRecurrence" @bill-add-on="onUpdateModel(index, $event)" @bill-feature="onUpdateModel(index, $event)" @bill-plan="onUpdateModel(index, $event)" @remove="form.model.voucherDetails.splice(index, 1)"></voucher-detail>
        </div>
      </el-card>
      <el-row :gutter="10">
        <el-col :lg="10" :offset="14" :sm="12" :xl="8" :xs="24">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>{{ $tc('total', 2) }}
                <small>/ {{ $tc(toCamelCase(form.model.voucherRecurrence)) }}</small>
              </span>
            </div>
            <table width="100%">
              <col style="width: 65%">
              <col style="width: 35%">
              <tfoot>
                <tr>
                  <td>{{ $tc('plan', 1) }}</td>
                  <td align="right">
                    {{ voucherPlanPrice | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('feature', 2) }}</td>
                  <td align="right">
                    {{ voucherPlanFeaturePrice | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('addOn') }}</td>
                  <td align="right">
                    {{ voucherAddOnPrice | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('iva') }}</td>
                  <td align="right">
                    {{ voucherIvaValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('total') }}</td>
                  <td align="right">
                    {{ voucherAmountTotal | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import VueWorker from 'vue-worker'
import { mapActions, mapGetters } from 'vuex'

/* app
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'
import model, { calculateVoucherPeriod, voucherDetailModel } from './model'

/* End of app
-------------------------------------------------- */

import form from '@/views/common/form'
import update from '@/views/common/update'
import rules from './rules'

Vue.use(VueWorker)
Vue.use(AsyncComputed)

export default {
  name: 'VoucherUpdate',
  extends: update('vouchers', { model }),
  components: {
    voucherDetail: _ => import('./voucher-detail'),
  },
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  methods: {
    ...mapFilters(['toCamelCase']),
    ...mapActions({
      setPlansByService: 'plansByService',
    }),
    onChangePeriod(recurrence) {
      const _this = this
      let months = 0

      switch (recurrence) {
        case 'Monthly':
          months = 0
          break
        case 'Yearly':
          months = 12
          break
        case 'Quarterly':
          months = 2
          break
        case 'Semiannual':
          months = 4
          break
        case 'ThreeYearly':
          months = 36
          break
        case 'EighthYearly':
          months = 96
          break
        default:
          break
      }
      _this.form.model = {
        ..._this.form.model,
        ...calculateVoucherPeriod(_this.form.model.voucherDate, months),
      }
    },
    onClickAddDetail() {
      const _this = this
      _this.form.model.voucherDetails.push(voucherDetailModel())
    },
    onUpdateChild() {
      const _this = this

      _this.form.model.voucherTaxBase = _this.voucherTaxBase
      _this.form.model.voucherIvaBase = _this.voucherIvaBase
      _this.form.model.voucherIvaPercent = _this.voucherIvaPercent
      _this.form.model.voucherIvaValue = _this.voucherIvaValue
      _this.form.model.voucherAmount = _this.voucherAmount
      _this.form.model.voucherAmountTotal = _this.voucherAmountTotal

      _this.onSubmit().then(result => {
        this.$confirm(
          `${_this.$tc('sendVoucherEmail')} ?`,
          `${_this.$tc('sendEmail')}`,
          {
            confirmButtonText: _this.$tc('send'),
            cancelButtonText: _this.$tc('cancel'),
            type: 'info',
          },
        ).then(() => {
          _this.form.loading = true
          _this.$http
            .patch(
              `vouchers/${_this.$route.params.id}/VoucherNew/emailNotification`,
            )
            .then(response => {
              _this.$message({
                message: _this.$tc('emailNotificationSuccess'),
                type: 'success',
              })
            })
            .finally(() => {
              _this.form.loading = false
            })
        })
      })
    },
    onChangeCompany(company) {
      const _this = this

      _this.form.model.voucherRecurrence =
        company.companyServiceFrequencyBilling

      _this.onChangeRecurrence(_this.form.model.voucherRecurrence)

      if (_this.form.model.voucherDetails.length === 1) {
        _this.form.model.voucherDetails[0].companyKey = company.companyKey
        _this.$refs.voucherDetail[0].onChangeCompany(company)
      }
    },
    async onChangeRecurrence(recurrence) {
      const _this = this

      _this.onChangePeriod(recurrence)

      if (_this.$refs.voucherDetail.length) {
        for (let index = 0; index < _this.$refs.voucherDetail.length; index++) {
          _this.$refs.voucherDetail[index].onChangePlanRecurrence(recurrence)
        }
      }
    },
    onSetService(service) {
      const _this = this
      _this.$http
        .get(`plans/findByService/${service.id}`)
        .then(result => _this.setPlansByService(result.data))
    },
    onUpdateModel(index, newModel) {
      this.$set(this.form.model.voucherDetails, index, newModel)
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
  },
  asyncComputed: {
    voucherPlanPrice: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanPrice)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanTaxBase: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanTaxBase)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanIvaPercent: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanIvaPercent)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanIvaValue: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanIvaValue)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanAmount: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanAmount)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanFeaturePrice: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanFeaturePrice)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanFeatureTaxBase: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanFeatureTaxBase)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanFeatureIvaValue: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanFeatureIvaValue)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherPlanFeatureAmount: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailPlanFeatureAmount)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAddOnPrice: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAddOnPrice)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAddOnTaxBase: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAddOnTaxBase)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAddOnIvaPercent: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAddOnIvaPercent)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAddOnIvaValue: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAddOnIvaValue)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAddOnAmountTotal: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAddOnAmountTotal)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherDiscount: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailDiscount)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherTaxBase: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailTaxBase)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherIvaBase: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailIvaBase)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherIvaPercent: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailIvaPercent)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherIvaValue: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailIvaValue)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAmount: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAmount)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
    voucherAmountTotal: {
      get: _this => new Promise((resolve, reject) => resolve(
        _this.form.model.voucherDetails
          .map(item => +item.voucherDetailAmountTotal)
          .reduce((a, b) => a + b, 0),
      ),
      ),
      default: 0,
    },
  },
  created() {
    this.get()
  },
}
</script>
